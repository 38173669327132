<template>
  <!-- animate__fadeInDown -->
  <!-- <transition
    name="fade"
    enter-active-class="animated animate__fadeInDown"
    leave-active-class="animated animate__fadeInDown"
  >
  </transition> -->
  <router-view />
</template>

<script>
    document.addEventListener('keydown',(e)=>{
            console.log('e',e)
            if((e.code == "MetaLeft" || e.code == "KeyR")){
                // delTimeCookie('day')
                var cookies = document.cookie.split(";");
                console.log('cookies',cookies)
                for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i];
                var eqPos = cookie.indexOf("=");
                var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
            }
        })
</script>

<style lang="scss">
@import "./style/reset.scss";
@import "./style/quill.bubble.css";
@import "./style/quill.core.css";
// @import "./style/quill.snow.css";
</style>
