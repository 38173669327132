import axios from "axios";
import { ElMessage } from "element-plus";
import store from "../store";

const service = axios.create({
  // baseURL: 'http://10.16.67.76:8080/wefound',
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000,
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // if (store.state.token) {
      // console.log("token存在");
      // config.headers["X-YAuth-Token"] = store.state.token;
      // config.headers.Authorization = abcdefghijklmnopqrstuvwxyz;
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    if (response.data.code === 401) {
      // store.dispatch("logout");
      ElMessage.error(response.data.msg);
    }
    return response;
  },
  // 请求失败
  (error) => {
    ElMessage.error(error.message);
    return Promise.reject(error);
  }
);

export default service;
