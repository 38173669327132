<template>
  <div :class="'footer'">
      <div class="foot-content">
        <div style="display: flex;align-items: center;">
          <img src="@/assets/img/download.png" alt="">
          <div>
              <p>天津市生态环境科学研究院 @Copyright TAES All right reserved</p>
              <a style="color:#fff;display: flex;align-items: center;" href="https://beian.miit.gov.cn/" target="_blank">津ICP备20005260号<img style="margin:0 5px" src="@/assets/img/batb.png"/>津ICP备20005260号</a>
          </div>
        </div>
        <p>联系地址:天津市南开区复康路17号联系电话:022-87671609 E-mail:huankeyuan@tj.gov.cn</p>
      </div>
  </div>
</template>

<script setup>
import { useRouter ,useRoute} from "vue-router";
import { ref, onMounted,onUpdated } from 'vue';
// 使用路由
let router = useRouter();
let route = useRoute();
const props = defineProps({
  name: {
    default: '',
    type: String
  }
})
const InnovationPlatform = ref(props.name)
const showFooter = ref(false)
const value = ref('1')
    const value2 = ref('2')
    const value3 = ref('3')
     const options = [
  {
    value: '1',
    label: '国家生态环境厅局',
  },
  {
    value: '2',
    label: '天津市生态环境局',
  },
  {
    value: '3',
    label: '天津环境科学学会',
  },
]
const goPath = (path) => {
  // 参数路径跳转
  router.push(path);
};
const showFooterFn = () => {
      // 根据路径判断是否显示底部 footer
      const path = props.name
      const footerPaths = ['index'] // 定义需要显示底部 footer 的路径
console.log('footerPaths.includes(path)', footerPaths.includes(path))
showFooter.value = footerPaths.includes(path)
      // return footerPaths.includes(path)
    }
onUpdated(() => {
  showFooterFn()
})
</script>

<style lang="scss" scoped>
.foot{
    border-top: 1px solid #bbb;
    font-size: 14px;
    background: #0E75BF;
    padding: 20px 0;
    box-sizing: border-box;
    color: #fff;
}
.footer{
    border-top: 1px solid #bbb;
    font-size: 14px;
    background: #0E75BF;
    padding: 20px 0;
    box-sizing: border-box;
    color: #fff;
}
.foot-ul{
     width: 1200px;
    margin: 0px auto 0;
    display: flex;
    font-size: 24px;
    font-weight: bold;
    justify-content: space-around;
    list-style: none;
    
}
#blogroll{
  height: 100%;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
#blogroll li{
  width: 33%;
  text-align: center;
}
.foot-ul span{
    color: #158Bf1;
    cursor: pointer;
}
.foot-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-top: 20px;
    line-height: 30px;
}
::v-deep .el-select__wrapper{
  height: 80px;
  text-align: center;
  font-size: 18px;
}

</style>
