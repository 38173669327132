import { LANG } from '@/constant'
import { getItem, setItem } from '../../utils/storage'
let lan = {
  zh: "content",
  en: "enContent",
};
export default {
  namespaced: true,
  state: () => ({
    language: getItem(LANG) || 'zh',
    mode: "content"
  }),
  mutations: {
    /**
     * 设置国际化
     */
    setLanguage(state, lang) {
      setItem(LANG, lang)
      state.language = lang
      state.mode = lan[lang]
      setItem("mode", state.mode)
    },
  },
  actions: {}
}
