import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'animate.css';
import locale from "element-plus/es/locale/lang/zh-cn";
// import i18n from './i18n'
const app = createApp(App)

app
  .use(store)
  .use(router)
  .use(ElementPlus,{ locale })
  .mount('#app')
