<template>
  <div :class="'footer'" ref="contentHeight">
    <ul id="blogroll">
      <li>
        <el-select
            v-model="value"
            placeholder="Select"
            size="small"
            style="width: 90%;height: 30px;font-size:12px;"
        >
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </li>
      <li style="margin-left: 4px;">
        <el-select
            v-model="value2"
            placeholder="Select"
            size="small"
            style="width: 90%;height: 30px;font-size:12px;"
        >
          <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </li>
      <li style="margin-left: 4px;">
        <el-select
            v-model="value3"
            size="small"
            placeholder="Select"
            style="width: 90%;height: 30px;font-size:12px;"
        >
          <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </li>
    </ul>
    <div class="foot-content">
      <div style="display: flex;align-items: center;">
        <img src="@/assets/img/download.png" alt="" >
        <div>
          <p>天津市生态环境科学研究院 @Copyright TAES All right reserved</p>
          <a style="color:#fff;display: flex;align-items: center;" href="https://beian.miit.gov.cn/#/" target="_blank">ICP备案编号：津ICP备20005260号<img
              style="margin:0 5px" src="@/assets/img/batb.png"/>津公网安备 12010402001198号</a>
        </div>
      </div>
      <p>联系地址:天津市南开区复康路17号&nbsp;&nbsp;&nbsp;联系电话:022-87671609&nbsp;&nbsp;&nbsp;E-mail:huankeyuan@tj.gov.cn</p>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {onUpdated, ref} from 'vue';
// 使用路由
let router = useRouter();
let route = useRoute();
const props = defineProps({
  name: {
    default: '',
    type: String
  }
})
const InnovationPlatform = ref(props.name)
const showFooter = ref(false)
const value = ref('1')
const value2 = ref('1')
const value3 = ref('1')
const options = [
  {
    value: '1',
    label: '生态环境部',
  },
  {
    value: '2',
    label: '中国环科院',
  },
  {
    value: '3',
    label: '中国环境学会',
  },
  {
    value: '4',
    label: '中国科技部',
  },
]
const options1 = [
  {value: '1', label: '北京市环科院'},
  {value: '2', label: '上海市环科院'},
  {value: '3', label: '重庆市环科院'},
  {value: '4', label: '四川省环科院'},
  {value: '5', label: '黑龙江环科院'},
  {value: '6', label: '新疆环科院'},
  {value: '7', label: '山西环保局'},
  {value: '8', label: '陕西环保局'},
  {value: '9', label: '吉林省环科院'},
  {value: '10', label: '辽宁省环科院'},
  {value: '11', label: '海南环保局'},
  {value: '12', label: '贵州省环科院'},
  {value: '13', label: '云南省环科院'},
  {value: '14', label: '广西环保局'},
  {value: '15', label: '广东环保局'},
  {value: '16', label: '河北环科院'},
  {value: '17', label: '湖北省环科院'},
  {value: '18', label: '浙江省环科院'},
  {value: '19', label: '甘肃环保局'},
  {value: '20', label: '福建省环科院'},
  {value: '21', label: '江苏省环科院'}
]
const options2 = [
  {
    value: '1',
    label: '中国环境监测总站',
  },
  {
    value: '2',
    label: '中日友好环境保护中心',
  },
  {
    value: '3',
    label: '中国环境报社',
  },
  {
    value: '4',
    label: '生态环境部核与辐射安全中心',
  },
  {
    value: '5',
    label: '生态环境部南京环境科学研究所',
  },
]
const goPath = (path) => {
  // 参数路径跳转
  router.push(path);
};
const showFooterFn = () => {
  // 根据路径判断是否显示底部 footer
  const path = props.name
  const footerPaths = ['index'] // 定义需要显示底部 footer 的路径
  console.log('footerPaths.includes(path)', footerPaths.includes(path))
  showFooter.value = footerPaths.includes(path)
  // return footerPaths.includes(path)
}
onUpdated(() => {
  showFooterFn()
})
</script>

<style lang="scss" scoped>
.foot {
  border-top: 1px solid #bbb;
  font-size: 14px;
  background: #0E75BF;
  padding: 20px 0;
  box-sizing: border-box;
  color: #fff;
}

.footer {
  border-top: 1px solid #bbb;
  font-size: 14px;
  background: #0E75BF;
  padding: 20px 0;
  box-sizing: border-box;
  color: #fff;
}

.foot-ul {
  width: 1200px;
  margin: 0px auto 0;
  display: flex;
  font-size: 24px;
  font-weight: bold;
  justify-content: space-around;
  list-style: none;

}

#blogroll {
  height: 100%;
  width: 1200px;
  margin: 0 auto 10px;
  display: flex;
  justify-content: center;

}

#blogroll li {
  width: 33%;
  text-align: center;
}

.foot-ul span {
  color: #158Bf1;
  cursor: pointer;
}

.foot-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // margin-top: 20px;
  line-height: 30px;
}

::v-deep .el-select__wrapper {
  height: 30px;
  text-align: center;
  font-size: 12px;
}

</style>
