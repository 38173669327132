<template>
  <div class="app-wrapper">
    <!-- 头部导航栏 -->
    <!-- v-show="$route.path!=='/login' -->
    <navbar />
    <!-- 主体内容区 -->

    <app-main />
<!-- {{ route.name }} -->
    <!-- 底部栏 -->
    <footerbar v-if="route.name == 'index'"></footerbar>
    <footers v-if="route.name != 'index'" ></footers>
  </div>
</template>
<script setup>
import { useRouter,useRoute } from "vue-router";
import Navbar from "../components/Navbar/index.vue";
import Footerbar from "../components/Footerbar/index.vue";
import Footers from "../components/Footer/index.vue";
import AppMain from "../components/AppMain/index.vue";
let route = useRoute()
</script>

<style lang="scss" scoped>
.app-wrapper {
  width: 100%;
  height: 100%;
}

</style>
