<template>
  <div class="app-main">
    <!-- <transition
      name="fade"
      enter-active-class="animated animate__fadeIn"
      leave-active-class="animated animate__fadeIn"
    > -->
      <router-view></router-view>
    <!-- </transition> -->
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.app-main{
  margin:16px 0;
  // min-height: 400px;
  overflow-y: auto;
}
</style>
