import { createRouter, createWebHashHistory } from 'vue-router'

import layout from "../layout/index.vue";

const routes = [
  // 首页
  {
    path: '/',
    redirect: '/app/index',
    component: layout,
    // 二级路由
    children: [
      //首页
      {
        path: '/app/index',
        name: 'index',
        component: () => import('../views/Index'),
        meta:{
          istrun:true
        }
      },
      //首页
      {
        path: '/app/searchlist',
        name: 'searchList',
        component: () => import('../views/searchList'),
        meta:{
          istrun:true
        }
      },
      // 机构简介
      {
        path: '/app/organizationProfile',
        name: 'OrganizationProfile',
        component: () => import('../views/OrganizationProfile'),
        meta:{
          istrun:true
        }
      },
      // 机构组织
      {
        path: '/app/organization',
        name: 'organization',
        component: () => import('../views/organization'),
        meta:{
          istrun:true
        }
      },
      // 创新平台
      {
        path: '/app/innovationPlatform',
        name: 'InnovationPlatform',
        component: () => import('../views/InnovationPlatform'),
        meta:{
          istrun:true
        }
      },
      // 技术成果
      {
        path: '/app/technologicalAchievement',
        name: 'TechnologicalAchievement',
        component: () => import('../views/TechnologicalAchievement'),
        meta:{
          istrun:true
        }
      },
      {
        path: '/app/keyTechnology',
        name: 'KeyTechnology',
        component: () => import('../views/TechnologicalAchievement/KeyTechnology'),
        meta:{
          istrun:true
        }
      },
      {
        path: '/app/mainAchievement',
        name: 'MainAchievement',
        component: () => import('../views/TechnologicalAchievement/MainAchievement'),
        meta:{
          istrun:true
        }
      },
      // 信息公开
      {
        path: '/app/informationDisclosure',
        name: 'InformationDisclosure',
        component: () => import('../views/InformationDisclosure'),
        meta:{
          istrun:true
        }
      },
      // // 二级详情
      {
        path: '/app/detailss',
        name: 'detailss',
        component: () => import('../views/Detailss')
      },
      // 三级详情
      {
        path: '/app/newsDetails',
        name: 'newsDetails',
        component: () => import('../views/newsDetails')
      },
      // 详情
      {
        path: '/app/newsDetailss',
        name: 'newsDetailss',
        component: () => import('../views/newsDetailss')
      },
      // 后台管理跳转过来的详情
      {
        path: '/app/systemDetails',
        name: 'systemDetails',
        component: () => import('../views/systemDetails')
      },
      // 通知公告详情
      {
        path: '/app/downloadfile',
        name: 'downloadfile',
        component: () => import('../views/downloadfile')
      }
    ]
  },





  // 上传文件测试
  {
    path: '/test',
    name: '上传文件测试',
    component: () => import('../test.vue')
  },
  // 表单测试
  {
    path: '/formtest',
    name: '表单测试',
    component: () => import('../FormTest.vue')
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
