// 主页接口

import request from "../utils/request";
// banne列表
export const findBannerList = () => {
  return request({
    url: "/app/home/findBannerList",
    method: "GET"
  });
};
// 首页分组式列表
export const findNewsList = () => {
  return request({
    url: "/app/home/findNewsList",
    method: "GET"
  });
};
// 全站搜索列表
export const findNewsListByBizColumn = (params) => {
  return request({
    url: "/app/news/findNewsListByBizColumn",
    method: "GET",
    params:params
  });
};
// 全站搜索列表
export const searchAll = (params) => {
  return request({
    url: "/app/news/searchAll",
    method: "GET",
    params
  });
};
// 信息公开搜索列表
export const searchXxgkAll = (params) => {
  return request({
    url: "/app/news/searchXxgkAll",
    method: "GET",
    params
  });
};
// 查看详情
export const findInfo = (params) => {
  return request({
    url: "/app/news/findInfo",
    method: "GET",
    params
  });
};
