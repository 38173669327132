<template>
  <div class="navbar">
    <!-- 头部信息栏 -->
    <div class="navbar-search">
      <div class="top-bar" @click="goPath('/app/index',1)" style="cursor: pointer;">
          <img src="@/assets/img/about_logo.png" alt="">
          <div class="search-top">
            <p class="title">
              <span class="title-name">天津市生态环境科学研究院</span>
              <span class="title-subname" style="font-size: 17px;text-align: center">TIANJINACADEMYOF ECO-ENVIRONMENTAL SCIENCES</span>
              <span class="title-subname" style="font-size: 20px;font-weight: bold;text-align: center;letter-spacing:2px;">天津市环境规划院、天津市低碳发展研究中心</span>
            </p>
      </div>
      </div>
      <div style="display:flex;align-items: center;">
              <el-input
                v-model="searchVal"
                style="width: 200px;height:40px;border-right: none;"
                size="large"
                placeholder="请输入关键字"
                class="input-with-select"
              >
              </el-input>
              <div class="input-append" @click="secrchList">
                <Search style="width: 15px;height: 15px;" /> <span style="padding-left: 5px;line-height: 15px;"> 搜索</span>
              </div>
      </div>
    </div>
   
    <!-- 头部导航栏 -->
    <div class="NavLan" v-if="$route.path !== '/app/detailss' && $route.path !== '/app/newsDetailss'">
        <ul class="nav-list">
            <li :class="showNav1?'nav-li-active':'nav-li'" @click="goPath('/app/index',1)">
                <span class="a">首页</span>
            </li>
            <!-- <span>|</span>  -->
            <li :class="showNav2?'nav-li-active':'nav-li'" @click="goPath('/app/organizationProfile',2)"><span class="a">走进环科院</span></li>
            <!-- <span>|</span> -->
            <!-- <li class="nav-li" @click="goPath('/app/organization')"><span class="a">机构组织</span></li> -->
            <!-- <span>|</span> -->
            <li :class="showNav3?'nav-li-active':'nav-li'" @click="goPath('/app/innovationPlatform',3)"><span class="a">创新平台</span></li>
            <!-- <span>|</span> -->
            <li :class="showNav4?'nav-li-active':'nav-li'"  @click="goPath('/app/mainAchievement',4)">
                <span class="a" >技术成果 </span>
                <!-- <img style="width: 20px;height: 20px;" id="submenu-image" src="@/assets/img/down.png" /> -->
                <!-- <ul class="nav-li-child">
                    <li class="child-li" @click="goPath('/app/keyTechnology')"><span>关键技术</span></li>
                    <li class="child-li" @click="goPath('/app/mainAchievement')"><span>主要成果</span></li>
                </ul> -->
            </li>
            <!-- <span>|</span> -->
            <li :class="showNav5?'nav-li-active':'nav-li'" @click="goPath('/app/informationDisclosure',5)"><span class="a" >信息公开</span></li>
        </ul>
    </div>
  </div>
</template>

<script setup>
import { computed, ref ,onMounted} from "vue";
import { useRouter,useRoute } from "vue-router";
import { useStore } from "vuex";
import { Search } from '@element-plus/icons-vue'
import { searchAll } from "../../api/index";
// 搜索

let searchVal = ref("");
const showNav1 = ref(false)
const showNav2 = ref(false)
const showNav3 = ref(false)
const showNav4 = ref(false)
const showNav5 = ref(false)

let route = useRoute()
let InnovationPlatform = route.name
// 使用路由
let router = useRouter();
const goPath = (path,value) => {
  if(value == 1){
    showNav1.value = true
    showNav2.value = false
    showNav3.value = false
    showNav4.value = false
    showNav5.value = false
  }
  if(value == 2){
    showNav2.value = true  
    showNav1.value = false
    showNav3.value = false
    showNav4.value = false
    showNav5.value = false
  }
  if(value == 3){
    showNav3.value = true
    showNav2.value = false
    showNav1.value = false
    showNav4.value = false
    showNav5.value = false
  }
  if(value == 4){
    showNav4.value = true
    showNav2.value = false
    showNav3.value = false
    showNav1.value = false
    showNav5.value = false
  }
  if(value == 5){
    showNav5.value = true
    showNav2.value = false
    showNav3.value = false
    showNav4.value = false
    showNav1.value = false
  }
  // 参数路径跳转
  router.push(path);
};
const secrchList = () =>{
  // searchVal
  
  router.push({
    path:'/app/searchlist',
    query:{searchVal:searchVal.value}
  })
 
}
// 语言切换
// const store = useStore();
// const language = computed(() => store.getters.language);
// console.log(language.value);
// 切换语言的方法
// const i18n = useI18n();
// const handleSetLanguage = (lang) => {
//   // console.log(lang);
//   i18n.locale.value = lang;
//   store.commit("app/setLanguage", lang);
// };
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'MyFont';
  src: url('@/assets/font/MyFont.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}
a {
  color: #fff;
}
.navbar-plat{
  position: fixed;
  top:0;
  left:0;
  right: 0;
  background: #fff;
}
.navbar {
  width: 100%;
  height: 100%;
  background: #fff;
  padding-top:10px;
  box-sizing: border-box;
 
// .nav-list span{
//     padding:0 30px;
//     box-sizing: border-box;
// }
  }
  .title{
    display: flex;
    flex-direction: column;
    // vertical-align: bottom;
    // justify-content: flex-end;   
    .title-name{
      font-size:36px;
      color: #3358A3;
      font-family: 'MyFont' ;
      letter-spacing: 5px;
      font-weight: bold;
      margin-bottom: -10px;
    } 
    .title-subname{
      font-size:18px;
      color: #3358A3;
      font-family: '微软雅黑';
    }
  }
  .navbar-search{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    // align-items: center;
    justify-content: space-between;
  }
  .top-bar {
    flex:1;
    height: 150px;
    display: flex;
    // justify-content: center;
    margin-bottom: 10px;
    .search-top{
      margin-left: 10px;
      display: flex;
      // flex-direction: column;
      // justify-content: center;
      align-items: center;
    }
    // p{
    //   // margin-left: 20px;
    //   display: flex;
    //   // flex-direction: column;
    //   // align-items: flex-end;
    //   // justify-content: center;
    //   font-size: 30px;
    //   color: #055688;
    //   font-weight: bold;
    //   // vertical-align: bottom;
    // }

  }

  // .NavLan {
  //   background: #158Bf1;
  //   width: 100%;
  //   height: 90px;
  .NavLan{
    background: #0E75BF;
    width: 100%;
    height: 60px;
    line-height: 60px;
    box-sizing: border-box;
}
.nav-list{
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    font-size: 18px;
    width: 1200px;
    margin: 0 auto;
    letter-spacing: 1px;

}
.nav-li{
    position: relative;
    cursor: pointer;
    flex:1;
    text-align: center;
}
.nav-li:hover .a{
    color: #fff;

 }
.nav-li:hover{
   display: block;
   color: #fff;
   background: #158Bf1;
}
.nav-li-active{
  position: relative;
    cursor: pointer;
    flex:1;
    text-align: center;
  // display: block;
   color: #fff;
   background: #158Bf1;
}
.child-li:hover span{
    color: #fff;
}
.nav-li-child{
    position: absolute;
    list-style: none;
    width: 200px;
    background: #158Bf1;
    top:90px;
    z-index: 10;
    text-align: center;
    display: none;
}
.child-li{
    cursor: pointer;
}
.child-li:nth-child(1){
    border-bottom: 1px solid #fff;
}
.nav-li:hover #submenu-image {
    content: url('@/assets/img/up.png');
  }
.input-append{
  width:60px;
  height: 40px;
  background: #158Bf1;
  color: #fff;
  border-radius: 0px 5px 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -3px;
  z-index: 99;
  cursor: pointer;
}
// }
</style>
